const buildServerUrl = (service) => {
    // service should normally run on api.<prefix>.keboola.com
    const stack = window.location.hostname.replace("api.", "");

    // localhost is an exception
    if (stack.includes('localhost') && service === 'connection') {
        return `https://localhost:8700`;
    }
    return `https://${service}.${stack}`;
}

export {
    buildServerUrl
}
