import configData from "./config.json"

const ApiSelect = ({service, onChange}) => {
    const localHostname = window.location.hostname.replace("api.", "");

    // get local configuration to properly exclude services
    let localConfiguration = Object.values(configData.stacks).find(stack => stack.hostname === localHostname)

    if (!localConfiguration) {
        console.error(`Configuration for hostname ${localHostname} not found.`);
        localConfiguration = {excludedServices: []};
    }

    // exclude services
    const servicesToUse = [];
    Object.keys(configData.services)
        .filter(item => !localConfiguration.excludedServices.includes(item))
        .forEach(key => {
            // openApiUrl is empty = spec file not available -> skip it
            if (configData.services[key].openApiUrl !== "") {
                servicesToUse.push(configData.services[key]);
            }
        });

    // render selectbox
    return (
        <select value={service} onChange={({target}) => {
            onChange(target.value);
        }}>
            {
                servicesToUse.map(
                    item => <option key={item.id} value={item.id}>{item.name}</option>
                )
            }
        </select>
    );
};

export default ApiSelect;
