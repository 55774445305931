import "./TopSidebar.css";

const TopSidebar = ({ children }) => {
  return (
    <div className="top-sidebar" slot="nav-logo">
      {children}
    </div>
  );
};

export default TopSidebar;
