import React from 'react';
import "rapidoc";
import {buildServerUrl} from "./helpers";
import configData from "./config.json";

class RapiDoc extends React.Component {
  constructor(props) {
    super(props);
    this.rapiDocRef = React.createRef();
    this.handleBeforeRender = this.handleBeforeRender.bind(this);
  }

  componentDidMount() {
    this.rapiDocRef.current.addEventListener('before-render', this.handleBeforeRender)
  }

  componentWillUnmount() {
    this.rapiDocRef.current.removeEventListener('before-render', this.handleBeforeRender)
  }

  handleBeforeRender(e) {
    const serviceObject = configData.services[this.props.service];

    e.detail.spec.servers = [
      {
        url: buildServerUrl(serviceObject.urlPrefix),
        computedUrl: buildServerUrl(serviceObject.urlPrefix)
      },
    ]
  }

  render() {
    const serviceObject = configData.services[this.props.service];
    const specUrl = `/specs/${serviceObject.file}.yaml`
    return (
      <rapi-doc
        ref={this.rapiDocRef}
        id="api-doc"
        spec-url={specUrl}
        primary-color="#1F8FFF"
        theme="light"
        font-size="largest"
        render-style="read"
        style={{height: "100vh", width: "100%"}}
        show-curl-before-try="true"
        allow-spec-file-load="false"
        allow-spec-url-load="false"
        show-header="false"
        allow-server-selection="false"
        show-method-in-nav-bar="as-colored-block"
        use-path-in-nav-bar="false"
        show-components="true"
        show-info="true"
        allow-search="true"
        allow-authentication="true"
        update-route="true"
        fill-request-fields-with-example="true"
        layout="row"
        match-type="regex"
        persist-auth="true"
        schema-style="table"
        default-api-server={buildServerUrl(serviceObject.urlPrefix)}
      >
        {this.props.children}
      </rapi-doc>
    );
  }
}

export default RapiDoc;
