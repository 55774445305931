import { useState } from "react";
import ApiSelect from "./ApiSelect";
import "./App.css";
import RapiDoc from "./RapiDoc";
import TopSidebar from "./TopSidebar";
import KeboolaLogo from "./logo.svg";

function App() {
  const [service, setService] = useState('manage');

  return (
    <RapiDoc service={service}>
      <TopSidebar>
        <img src={KeboolaLogo} alt="Keboola logo" height="30px" />
        <hr />
        <ApiSelect service={service} onChange={setService} />
      </TopSidebar>
    </RapiDoc>
  );
}

export default App;
